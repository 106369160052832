<template>
	<div class="row">
		<div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-sm-6 col-lg-3 px-1 mb-3">
                     <label><i class="far fa-filter color-theme me-1"></i> {{ $t('admPedidos.filtro') }}</label>
                     <v-select title="Desktop" :options="$t('admPedidos.filtros')" v-model="pesquisa.tipo" label="value" :reduce="f => f.key" :searchable="false" :clearable="false" @input="pesquisa.valor = null;" />
                     <select title="Mobile" class="form-control" v-model="pesquisa.tipo" @change="pesquisa.valor = null;">
                        <option v-for="(option, index) in $t('admPedidos.filtros')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Pacote'">
                     <label><i class="far fa-backpack color-theme me-1"></i> {{ $t('admPedidos.pacote') }}</label>
                     <v-select title="Desktop" :placeholder="$t('admPedidos.pacoteDesc')" :options="listaPacotes" v-model="pesquisa.valor" label="nome" :reduce="p => p.id">
                        <template v-slot:no-options>{{ $t('admPedidos.semResultados') }}</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.valor">
                        <option v-for="(option, index) in listaPacotes" :key="index" :value="option.id">{{ option.nome }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Script'">
                     <label><i class="far fa-file-alt color-theme me-1"></i> {{ $t('admPedidos.script') }}</label>
                     <v-select title="Desktop" :placeholder="$t('admPedidos.scriptDesc')" :options="listaScripts" v-model="pesquisa.valor" label="nome" :reduce="s => s.id">
                        <template v-slot:no-options>{{ $t('admPedidos.semResultados') }}</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.valor">
                        <option v-for="(option, index) in listaScripts" :key="index" :value="option.id">{{ option.nome }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Dono'">
                     <label><i class="far fa-user color-theme me-1"></i> {{ $t('admPedidos.dono') }}</label>
                     <v-select title="Desktop" :placeholder="$t('admPedidos.donoDesc')" :options="listaDonosScript" v-model="pesquisa.valor" label="nome" :reduce="d => d.id">
                        <template v-slot:no-options>{{ $t('admPedidos.semResultados') }}</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.valor">
                        <option v-for="(option, index) in listaDonosScript" :key="index" :value="option.id">{{ option.nome }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Categoria'">
                     <label><i class="far fa-stream color-theme me-1"></i> {{ $t('admPedidos.categoria') }}</label>
                     <v-select title="Desktop" :placeholder="$t('admPedidos.categoriaDesc')" :options="$t('categorias')" v-model="pesquisa.valor" label="value" :reduce="c => c.key">
                        <template v-slot:no-options>{{ $t('admPedidos.semResultados') }}</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.valor">
                        <option v-for="(option, index) in $t('categorias')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Autor'">
                     <label><i class="far fa-user color-theme me-1"></i> {{ $t('admPedidos.autor') }}</label>
                     <input type="text" class="form-control" :placeholder="$t('admPedidos.autorDesc')" v-model="pesquisa.valor" maxlength="200" @keyup.enter="buscarVendasStatus" />
                  </div>
                  <div class="col-9 col-lg-4 px-1 mb-3">
                     <label><i class="far fa-calendar color-theme me-1"></i> {{ $t('admPedidos.data') }}</label>
                     <datePicker :format="'DD/MM/YYYY'" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
                  <div class="col-3 col-lg-2 px-1 mb-3 align-self-end">
							<button type="button" class="btn btn-primary w-100" @click="buscarPedidos">
								<i class="fas fa-search font-13"></i>
							</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<div class="col-12" v-for="(compra, index) in pesquisa.resultado" :key="index">
         <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
            <div class="card-body p-12 pe-1">
               <div class="row m-0 font-12">
                  <div class="col-md-8 px-1 align-self-center cursor-pointer" @click="abrirModal(compra)">
                     <div class="row m-0">
                        <div class="col-12 p-0 text-center text-md-start mb-2">
                           <div class="limitador font-16 weight-600">{{ formatDateDay(compra.dataCompra) }} {{ formatDateTime(compra.dataCompra) }}</div>
                        </div>
                        <div class="col-6 p-0 d-none d-md-inline">
                           <div class="limitador">
                              <i class="far fa-box color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> {{ $t('admPedidos.pacote') }}:</strong> 
                              {{ compra.pacote.length }}
                           </div>
                        </div>
                        <div class="col-6 p-0 d-none d-md-inline">
                           <div class="limitador">
                              <i class="far fa-dollar-sign color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> {{ $t('admPedidos.pagamento') }}:</strong> 
                              {{ compra.pagamento == null ? '-' : compra.pagamento }}
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-14">
                     <span class="limitador-2 cursor-pointer" @click="alterarStatus(compra, index)">
                        <i class="far fa-sync font-13"></i> <br/> 
                        <strong title="Status" :class="compra.status == 'Finalizado' ? 'text-success' : compra.status == 'Cancelado' ? 'text-danger' : 'text-warning'">{{ compra.status }}</strong>
							</span>
                  </div>

                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-15">
                     <div class="limitador-2">
                        <small class="font-14 color-theme">R$</small>
                        <strong title="Valor"> {{ compra.valorTotal == null ? '-' : parseFloat(compra.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</strong>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12 text-center my-5" v-if="pesquisa.resultado.length == 0">{{ $t('admPedidos.semResultados2') }}</div>
		
		<!-- modalVisualizar -->
		<div class="modal fade" id="modalVisualizar" tabindex="-1" aria-labelledby="modalVisualizarLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalVisualizarLabel">{{ $t('admPedidos.verPedidoTitulo') }} (Nº {{ modal.id }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1">
                        <div class="card mb-2">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admPedidos.infoPedidoTitulo') }}</h6>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">{{ $t('admPedidos.campoNomeCliente') }}: </strong> {{ modal.nomeCompleto }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">{{ $t('admPedidos.campoIdCliente') }}: </strong> {{ modal.idUsuario }}
                                 </div>
                                 <div class="col-12 px-1 mb-1" :class="modal.status == 'Finalizado' ? 'text-success' : modal.status == 'Cancelado' ? 'text-danger' : 'text-warning'">
                                    <strong class="color-theme me-1">Status: </strong> <strong>{{ modal.status }}</strong>
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">{{ $t('admPedidos.campoValor') }}: </strong> R$ {{ parseFloat(modal.valorTotal).toFixed(2) }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">{{ $t('admPedidos.campoPagamento') }}: </strong> {{ modal.pagamento }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
												<strong class="color-theme me-1">{{ $t('admPedidos.campoTransacao') }}: </strong> {{ modal.transactionId != null ? modal.transactionId : 'Não encontrado' }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
												<strong class="color-theme me-1">{{ $t('admPedidos.campoDataCompra') }}: </strong>
                                    {{ new Date(modal.dataCompra.year +'/'+ modal.dataCompra.monthValue +'/'+ modal.dataCompra.dayOfMonth +' '+ modal.dataCompra.hour +':'+ modal.dataCompra.minute).toLocaleString() }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
												<strong class="color-theme me-1">{{ $t('admPedidos.campoMovimentacao') }}: </strong>
                                    {{ new Date(modal.dataUltMov.year +'/'+ modal.dataUltMov.monthValue +'/'+ modal.dataUltMov.dayOfMonth +' '+ modal.dataUltMov.hour +':'+ modal.dataUltMov.minute).toLocaleString() }}
                                 </div>
											<div class="col-12 px-1 mb-1">
												<strong class="color-theme me-1">{{ $t('admPedidos.campoUrlPagamento') }}: </strong> {{ modal.urlPagamento != null ? modal.urlPagamento : 'Não encontrado' }}
											</div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-12 px-1">
                        <div class="card mb-2">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admPedidos.pacote') }}</h6>
                                    <hr>
                                 </div>
                                 
                                 <div class="col-12 px-1" v-for="(produto, index) in modal.pacote" :key="index">
												<div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''">
													<div class="card-body">
														<h6>{{ produto.nome }}</h6>
														<p class="mb-0"><small class="color-theme">R$</small> {{ parseFloat(produto.valor).toFixed(2) }}</p>
													</div>
												</div>
											</div>
                                 <div class="col-12 px-1 py-5 text-center" v-if="modal.pacote == null || modal.pacote.length == 0">{{ $t('admPedidos.nenhumPacote') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('admPedidos.botaoFechar') }}</button>
               </div>
            </div>
         </div>
      </div>
	</div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Pedidos',
	data : function () {
		return {
			pesquisa: {'tipo': 'Dono', 'valor': null, 'data': [new Date(), new Date()], 'resultado': []},
			modal: {
				'id': null, 'idUsuario': null, 'nomeCompleto': null, 'transactionId': null, 'idPagamento': null, 'dataCompra': {}, 'dataUltMov': {}, 
				'status': null, 'pagamento': null, 'urlPagamento': null, 'valorTotal': 0, 'pacote': []
			}
		}
	},
   computed: {
      ... mapState({
			listaDonosScript: state => state.listaDonosScript,
			listaPacotes: state => state.listaPacotes,
			listaScripts: state => state.listaScripts,
			urlRest: state => state.urlRest
      })
   },
	methods: {
		buscarPedidos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.resultado = []

			this.$axios({
				method: 'post',
				url: this.urlRest +'pagamento/searchPedidos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
				
			}).then(response => {
				this.pesquisa.resultado = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: this.$t('sessaoExpirada')
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		alterarStatus : function (compra, index) {
         Swal.fire({
            icon: 'info',
            title: this.$t('admPedidos.alterarStatus'),
            input: 'select',
            inputOptions: this.$t('admPedidos.status'),
            inputValue: compra.status,
            inputPlaceholder: this.$t('admPedidos.alterarStatusDesc'),
            showCancelButton: true,
            cancelButtonText: this.$t('admPedidos.botaoCancelar'),
            confirmButtonText: this.$t('admPedidos.botaoConfirmar'),
            inputValidator: (value) => {
               if (!value) {
                  return this.$t('admPedidos.alterarStatusValidacao')
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'pagamento/alterarstatuscompra',
                  params:{
                     idVenda: compra.id,
                     status: result.value
                  }
               }).then(() => {
                  this.pesquisa.resultado[index].status = result.value

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('admPedidos.alterarStatusSucesso')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
		abrirModal : function (compra) {
			this.modal = JSON.parse(JSON.stringify(compra))
			$('#modalVisualizar').modal('show')
		},
		formatDateDay(data) {
         const { dayOfMonth, month, year } = data;
         const formattedDayOfMonth = String(dayOfMonth).padStart(2, '0');
         const formattedMonth = this.getMonthNumber(month);
         const formattedYear = year;
         const formattedDate = `${formattedDayOfMonth}/${formattedMonth}/${formattedYear}`;

         return formattedDate;
      },
      formatDateTime(data) {
			const { hour, minute } = data;
			const formattedHour = String(hour).padStart(2, '0');
			const formattedMinute = String(minute).padStart(2, '0');
			const formattedTime = `${formattedHour}:${formattedMinute}`;

			return formattedTime;
		},
		getMonthNumber(month) {
			const months = {
            JANUARY: '01',
            FEBRUARY: '02',
            MARCH: '03',
            APRIL: '04',
            MAY: '05',
            JUNE: '06',
            JULY: '07',
            AUGUST: '08',
            SEPTEMBER: '09',
            OCTOBER: '10',
            NOVEMBER: '11',
            DECEMBER: '12'
			};

			return months[month];
		}
	},
	mounted() {
      this.$store.dispatch('buscarDonosScript')
      this.$store.dispatch('searchAllPacotes')
      this.$store.dispatch('searchAllScripts')
	}
}

</script>